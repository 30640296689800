<template>
  <v-chip style="border-width: 2px" v-bind="$attrs" :color="color" outlined >
    <span class="font-weight-black" >{{ estado }}</span>
  </v-chip>
</template>

<script>
const estados = {
  PENDIENTE: "#0197F6",
  TRAMITANDO: "#8CBA80",
  ANULADO: "#93032E",
  DETENIDO: "#A51C30",

  "PDTE.FACTURA": "#296EB4",
  "PDTE.DOCUMENTO": "#225C96",
  "PDTE.COMPROBACION": "#2666A6",
  "PDTE.PAGO": "#FDB833",
  PAGADO: "#FDAD0D",

  "A DEVOLVER": "#CF8588",
  DEVOLVIENDO: "#DE3F4F",
  DEVUELTO: "#C52233",
  CERRADO: "#BE948F",
  PERDIDO: "#A7333F",
  "SIN COTEJAR": "#D49288",
  COTEJADO: "#BE9B88",
  BORRADOR: "#3C4F76",
  "EN REVISIÓN": "#434371",

  "PENDIENTE VPT" : "#434371"
};

export default {
  props: {
    estado: String,
  },
  computed: {
    color() {
      return estados[this.estado] || "";
    },
  },
};
</script>

<style></style>
